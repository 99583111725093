import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Button } from "./Button";

interface Data {
    label: string;
    number: number;
    percentage: string;
}

const formatNumberWithCommas = (number: number): string => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const DonutChart = ({ data, buttonLink, buttonLabel }) => {
    const svgRef = useRef(null);
    buttonLink = !/^https?:\/\//i.test(buttonLink)
        ? "http://" + buttonLink
        : buttonLink; // Ensures external link has proper formatting

    useEffect(() => {
        const width = parseInt(d3.select(svgRef.current).style("width"));
        const height = width;
        const radius = Math.min(width, height) / 4;

        const svg = d3.select(svgRef.current).attr("viewBox", "0 0 100 65");
        svg.selectAll("*").remove();

        // Sets color for donut chart
        const baseColor = d3.rgb("#7EAAFF");

        // Sets opacity range based on baseColor and set range value
        const opacityScale = d3
            .scaleLinear()
            .domain([0, data.length - 1])
            .range([1, 0.3]);

        // Method to process and sort the data
        const pie = d3
            .pie<Data>()
            .value((d) => d.number)
            .sort((a, b) => d3.descending(a.number, b.number));

        // Defines the outer donut size and donut hole size
        const arc = d3
            .arc()
            .innerRadius(radius * 0.45)
            .outerRadius(radius - 2);

        // Primary SVG group
        const svgGroup = svg
            .attr("width", String(width) + "%")
            .attr("height", String(height - 10) + "%")
            .append("g")
            .attr("transform", `translate(${width / 2}, ${height / 3})`);

        // Draws the donut chart slices
        svgGroup
            .selectAll("path")
            .data(pie(data))
            .enter()
            .append("path")
            .attr("d", arc as any)
            .attr("fill", (d): any => {
                const opacity = opacityScale(d.index);
                return d3.rgb(baseColor.r, baseColor.g, baseColor.b, opacity);
            })
            .attr("stroke", "#fff")
            .attr("stroke-width", "0.25px");

        // Defines the "circle" that the inner percentage labels are aligned to
        const labelArc = d3
            .arc()
            .outerRadius(radius - 15)
            .innerRadius(radius * 0.95);

        // Adds and controls the inner labels, or the percentages inside
        svgGroup
            .selectAll(".inner-label")
            .data(pie(data))
            .enter()
            .append("text")
            .attr("class", "inner-label")
            .attr(
                "transform",
                (d) => `translate(${labelArc.centroid(d as any)})`
            )
            .text((d) => d.data.percentage)
            .attr("dy", "1.5px")
            .style("text-anchor", "middle")
            .attr("font-size", "0.2rem");

        // Defines the "circle" that the outer info labels are aligned to
        const outerArc = d3
            .arc()
            .innerRadius(radius * 1)
            .outerRadius(radius * 1.3);

        // Prepare data for outer labels with centroids
        let outerLabelsData = pie(data).map((d) => {
            const pos = outerArc.centroid(d as any);
            return { ...d, x: pos[0], y: pos[1] };
        });

        // Render adjusted outer labels
        const outerLabel = svgGroup
            .selectAll(".outer-label")
            .data(outerLabelsData)
            .enter()
            .append("g")
            .attr("class", "outer-label")
            .attr("transform", (d) => `translate(${d.x}, ${d.y})`);

        // Add text labels
        outerLabel
            .append("text")
            .text((d) => d.data.label)
            .attr("dy", "-0.5em")
            .attr("text-anchor", "middle")
            .attr("font-size", "0.15rem")
            .attr("fill", "#7C7C7C");

        // Add numbers below labels
        outerLabel
            .append("text")
            .text((d) => `($${formatNumberWithCommas(d.data.number)})`)
            .attr("dy", "1em")
            .attr("text-anchor", "middle")
            .attr("font-size", "0.15rem")
            .attr("fill", "#7C7C7C");

        // Update leader lines for adjusted labels
        svgGroup
            .selectAll(".leader-line")
            .data(pie(data))
            .enter()
            .append("polyline")
            .attr("class", "leader-line")
            .attr("points", function (d: any, i): any {
                const posA = labelArc.centroid(d).map((coord) => coord * 1.25);
                const posB = outerArc.centroid(d).map((coord) => coord * 0.85);
                const posC = [
                    outerLabelsData[i].x * 0.85,
                    outerLabelsData[i].y * 0.85,
                ];
                return [posA, posB, posC];
            })
            .style("fill", "none")
            .style("stroke", "black")
            .style("stroke-width", "0.1px");
    }, []);

    return (
        <div
            style={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <svg ref={svgRef} style={{ width: "100%" }}></svg>
            <Button link={buttonLink} label={buttonLabel} />
        </div>
    );
};

export default DonutChart;
